.App {
  height: 100%;
  width: 100%;
  text-align: center;
  background-color: #272727;
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1, h2, h3, h4, h5, h6, p, span {font-family:"Poppins"; color:white}

.nav-main {
  font-family: 'Poppins';
  font-weight: 600;
  color: #ffffff;
  text-decoration: none;
}
.nav-scrolllink {
  font-family: 'Poppins';
  font-weight: 400;
  color: #ffffff;
  text-decoration: none;

}

.nav-brand {
  font-family: 'Poppins';
  font-weight: 500;
  color: red;
  font-size: large;
}

.welcome { 
  font-weight: 700;
  font-size: 64px;
}

.welcomeParagraph {
  font-weight: 500;
  font-size: 20px;
  color: white;
  margin-top: 24px;
}


@media only screen and (max-width: 760px) { 
  .welcome { 
    font-size: 48px;
  }

}


.mainCol {
  text-align: start;
}

.container {
  margin-top: 24px;
}

.maskImage {
  width: 200px;
  height: 200px;
  margin-top: 34px;
  border-radius: 20px;
}

@media only screen and (max-width: 760px) {
  .maskImage {
    width: 40vw;
    height: 40vw;
  }
}


.mainTitle {
  font-size: 40px;
  font-weight: 700;
  margin-top: 18px;
  margin-bottom: 24px;
}

.mainParagraph {
  font-size: 20px;
}

.mainCountdownTitle {
  font-size: 40px;
  font-weight: 700;
  margin-top: 18px;
  margin-bottom: 24px;
}


.countdownTitle {
  font-weight: 500;
  font-size: 40px;
  color: #FFE400;
  font-family:"Poppins"; 
}

.countSubTitle {
  font-size: 20px;
}

@media only screen and (max-width: 760px) { 
  .countdownTitle { 
    font-size: 28px;
  }

  .mainCountdownTitle {
    font-size: 28px;
  }

  .countSubTitle {
    font-size: 14px;
  }
  

}

@media only screen and (max-width: 320px) { 
  .countdownTitle { 
    font-size: 24px;
  }

  .mainCountdownTitle {
    font-size: 24px;
  }

  .countSubTitle {
    font-size: 12px;
  }
  

}

.subImage {
  width: 5vw;
  height: 5vw;
  background-color: white;
  border-radius: 20px;
  margin: 4px;
}

@media only screen and (max-width: 760px) {
  .subImage {
    display: none;
  }
}


.subTitle {
  font-size: 30px;
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 34px;
}

.charityImage {
  border-radius: 20px;
  width: 200px;
  height: 200px;
  background-color: #ffffff;
}

.charityLink {
  text-decoration: none;
}

.charityParagraph {
  font-size: 20px;
}

@media only screen and (max-width: 760px) {
  .charityImage {
    width: 25vw;
    height: 25vw;
  }

  .charityParagraph {
    font-size: 16px;
  }

}


.leftTraitsCol {
  text-align: start;
}

.rightTraitsCol {
  text-align: end;
}

.boxParagraph {
  font-size: 20px;
}

.boxParagraphTitle {
  font-size: 20px;
  font-weight: 700;
}

.traitImage {
  width: 5vw;
  height: 5vw;
  background-color: white;
  border-radius: 20px;
  margin: 10px;
}

@media only screen and (max-width: 760px) {
  .boxParagraph {
    font-size: 16px;
  }
  .boxParagraphTitle {
    font-size: 16px;
  }

  .traitImage {
    width: 20vw;
    height: 20vw;
  }

  .hideImg {
    display: none;
  }

}

.mainParagraphTitle {
  font-size: 20px;
  font-weight: 700;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.subParagraph {
  font-weight: 400;
  color: #747474;
}


.mintImage {
  width: 28vw;
  height: 28vw;
  margin-top: 20px;
}

.listParagraph {
  font-size: 20px;
}

@media only screen and (max-width: 760px) {
  .mintImage {
    display: none;
  }

}


.socialMediaLink {
  font-size: 16px;
  text-decoration: none;
}

.socialMediaImage {
  border-radius: 20px;
  width: 150px;
  height: 150px;
}

.socialMediaParagraph {
  font-size: 16px;
}


@media only screen and (max-width: 760px) {
  .socialMediaImage {
    width: 20vw;
    height: 20vw;
  }

  .socialMediaParagraph {
    font-size: 14px;
  }

}

.provenanceParagraph {
  font-size: 16px;
}

.provenanceTitle {
  font-size: 20px;
  font-weight: 700;
}

.creatorImage {
  border-radius: 20px;
  width: 150px;
  height: 150px;
  background-color: #ffffff;
}

.mainCreatorParagraph {
  font-size: 16px;
}

.mainCreator {
  font-size: 16px;
  font-weight: 700;
}

@media only screen and (max-width: 760px) {
  .creatorImage {
    width: 20vw;
    height: 20vw;
  }

  .mainCreator {
    font-size: 14px;
  }

}

.footerImage {
  width: 50px;
  height: 50px;
  margin-right: 8px;
  margin-left: 8px;
  margin-bottom: 16px;
}

.copyrightText {
  font-size: 14px;
}
@media only screen and (max-width: 760px) { 
  .footerImage {
    width: 8vw;
    height: 8vw;

  }

  .copyrightText { 
    font-size: 10px;
  }


}

